<template>
	<nav class="navigation-bar pl-5 pr-5">
		<div class="nav-left">
			<span class="home-icon link-underlined">
				<img src="./../../assets/menu-icons/MENU_home.svg" alt="🏠" class="menu__icon">
			</span>
			<span class="ml-2 link-underlined" @click="goHome()">{{ $t('home') }}</span>

			<span v-for="(folder, index) in computedPath" :key="index" class="folder">
				<span class="separator"> > </span>
				<span v-if="folder !== 'comparison'" class="folder-name link-underlined" @click="goToProject(index)">{{ folder
					}}</span>
				<span v-else class="folder-name comparison">{{ folder }}</span>
			</span>
		</div>
	</nav>
</template>

<script>
export default {
	data() {
		return {
			computedPath: []
		};
	},
	methods: {
		goToFolder(index) {
			this.$emit('navigateToFolder', this.computedPath.slice(0, index + 1));
		},
		goHome() {
			this.$router.push({
				name: 'Dashboard'
			})
		},
		goToProject(index) {
			// console.log("index: ", index)
			let projectId;
			if (index === 0)
				projectId = this.comparissonProject._id
			else
				projectId = this.activeProject._id
			this.close(projectId)
		},
		close(projectId) {
			const mainAnalysis = this.analysisArray.filter(item => item.project._id !== projectId)
			this.$store.commit("analysisV2/REMOVE_PROJECT_BY_ID", projectId);
			this.$store.commit("analysisV2/SET_MAIN_ANALYSIS", mainAnalysis[0])
			this.$store.commit("project/SET_ACTIVE_PROJECT", mainAnalysis[0].project)
			this.gotoHeatmapAnalysis(mainAnalysis[0].project._id)
		},
		gotoHeatmapAnalysis(projectId) {
			this.$router.push(`/en/project/${projectId}/project-analysis/`)
		},
		goToFolderPage(index) {
			// Define navigation logic to go to the folder page
		},
		updateBreadcrumbPath() {
			const urlSegments = this.$route.path.split('/').filter(Boolean);
			const computedPath = [];

			if (urlSegments.includes('project-analysis')) {
				//   const projectId = urlSegments[2];
				// const folderId = this.activeProject.folderId
				// const folderName = this.folderName(folderId)
				const projectName = this.activeProject.name

				// if(folderName)
				// 	computedPath.push(folderName)
				computedPath.push(projectName);

				if (urlSegments.includes('comparisson')) {
					computedPath.push('comparison');

					// const secondProjectId = urlSegments[4];
					const secondProjectName = this.comparissonProject.name
					computedPath.push(secondProjectName);
				}
			} else if (urlSegments.includes('project')) {
				computedPath.push(this.$store.getters['project/getProjectById'](urlSegments[2]).name);
			}

			this.computedPath = computedPath;
		},
		folderName(folderId) {
			const empty = ''
			if (!folderId) return empty
			const folder = this.folderList.find((folder) => folder.id === folderId);
			return folder ? folder.name : empty;
		}
	},
	mounted() {
		this.updateBreadcrumbPath();
	},
	watch: {
		'$route.path'() {
			this.updateBreadcrumbPath();
		}
	},
	computed: {

		activeProject() {
			return this.$store.getters['project/getActiveProject'];
		},
		comparissonProject() {
			return this.$store.getters['project/getProjectToCompare']
		},
		folderList() {
			return this.$store.getters['folder/getFolderList']
		},
		analysisArray() {
			return this.$store.getters['analysisV2/getAnalysisArray']
		}

	}
};
</script>



<style scoped>
.comparison {
	text-decoration: none;
	cursor: default;
}

.navigation-bar {
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
}

.nav-left {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.link-underlined:hover {
	text-decoration: underline;
}

.link-underlined {
	cursor: pointer;
}

.home-icon {
	font-size: 1.5rem;
	align-self: center;
}

.folder {
	display: flex;
	align-items: center;
}

.separator {
	margin: 0 0.5rem;
}

.menu__icon {
	color: #2d3648;
	width: 24px;
	height: 24px;
	margin-top: -4px;
}

.nav-right {
	display: flex;
	align-items: center;
}

.nav-button {
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 6px;
	padding: 0.5rem 1rem;
	margin-left: 1rem;
	cursor: pointer;
	transition: background-color 0.3s, box-shadow 0.3s;
}

.nav-button:hover {
	background-color: #ddd;
}

.nav-button.active {
	background-color: lightgray;
}
</style>
